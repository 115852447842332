import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import {createSmols} from "./graphs"; // Import precompiled Bootstrap css and importing additional files

let spacer = 0

function createGreenSpace(title){
  if (spacer % 4 !== 0){
    let rows = document.getElementsByClassName('row');
    rows[rows.length-1].innerHTML +=
      '<div class="col-sm-3">' +
      '<div id =' + title.replace(/\s+/g, '') + '></div>' +
      '</div>'
    spacer++
  }
  else{
    spacer++
    document.getElementById('GreenContainer').innerHTML +=
      '<div class="row">' +
      '<div class="col-sm-3">' +
      '<div id =' + title.replace(/\s+/g, '') + '></div>' +
      '</div>' +
      '</div>'
  }
}

function createRedSpace(title){
  if (spacer % 4 !== 0){
    let rows = document.getElementsByClassName('row');
    rows[rows.length-1].innerHTML +=
      '<div class="col-sm-3">' +
      '<div id =' + title.replace(/\s+/g, '') + '></div>' +
      '</div>'
    spacer++
  }
  else{
    spacer++
    document.getElementById('RedContainer').innerHTML +=
      '<div class="row">' +
      '<div class="col-sm-3">' +
      '<div id =' + title.replace(/\s+/g, '') + '></div>' +
      '</div>' +
      '</div>'
  }
}

fetch('https://camelviewward.s3.us-east-2.amazonaws.com/data.json')
  .then(response => response.json())
  .then(data => {
    for (let datumKey in data['Green']){
      createGreenSpace(data['Green'][datumKey])
    }
    spacer = 0
    for (let datumKey in data['Red']){
      createRedSpace(data['Red'][datumKey])
    }
    for (let university in data){
      try {
        createSmols(university,data[university])
      }
      catch (error){
        //empty, this is intentional to just clean up some of the unnecessary data.
      }
    }
  });


