import Plotly from "plotly.js-dist";
import $ from "jquery";

function createSmols(title, obj){
  let x = [];
  let y = [];
  let total = [];
  let rolling_average = ''
  let saved_title = title

  for(let data in obj){
    x.push(obj[data].date);
    y.push(obj[data].value);
    total.push(obj[data].total);
    if(obj[data].rolling_average){
      rolling_average = obj[data].rolling_average
    }
    }
  let color  = 'rgb(255,255,0)'
  if(Math.sign(parseFloat(rolling_average[0])) > 0){
    color = 'rgb(200, 0, 0)'
  }
  else{
    color = 'rgb(0,130,0)'
  }

  let plot = document.getElementById(title.replace(/\s+/g, ''))

  let data = [
  //   {
  //   x:x,
  //   y:y,
  //   type: 'line'
  // },
    {
      x:x,
      y:rolling_average,
      customdata: total,
      type: "line",
      hoverinfo: 'none',
      line:{
        color: color
      }
    }];

  let layout = {
    title: title,
    height: 300,
    width: 300,
    showlegend: false,
    hovermode: 'closest',
    hoverlabel: { bgcolor: "#FFFF" },
    yaxis: {
      autorange: true,
      showgrid: false,
      zeroline: false,
      showline: false,
      autotick: true
    },
    xaxis: {
      type: 'date',
      tickformat: '%b\n',
      autorange: true,
      showgrid: false,
      zeroline: false,
      showline: false,
      autotick: true
    }
  };

  Plotly.newPlot(title.replace(/\s+/g, ''), data, layout,{displayModeBar:false});

  plot.on('plotly_click', function(){
    $("#graphModalTitle").innerText = saved_title
    $("#graphModal").modal("toggle")
    layout.height = 500
    layout.width = 750
    layout.xaxis.tickformat = '%x'
    data[0].hoverinfo = 'text'
    data[0].hovertemplate =
    "14-Day Average New Cases: %{y}<br>" +
      "Total Cases: %{customdata}<br>" +
      "Date Recorded: %{x| %x}<br>" +
      "<extra></extra>"
    Plotly.newPlot('modal_graph_body', data, layout,{displayModeBar:false});
  });

}

export {
  createSmols
}
